import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { MapGoogle } from "../../components/MapGoogle";
import data from "../../json/data.json";
import { LocationDetail } from "../../models/Location";
import { FilteredLocation } from "../../components/FilteredLocation";
import { debounce } from "lodash";
import { LocationContext } from "../../contexts/LocationContext";
import { AccessibilityContext } from "../../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { getObjectForm, getPunktForm } from "../../helpers/correctForm";

const MemoizedMapGoogle = React.memo(MapGoogle);

export const Localization = () => {
    const [search, setSearch] = useState<string>("");
    const { locations } = useContext(LocationContext);
    const [filteredLocations, setFilteredLocations] = useState<LocationDetail[]>([]);
    const { isContrast } = useContext(AccessibilityContext);
    const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);
    const headerRef = useRef<HTMLHeadingElement>(null);
    const [mapKey, setMapKey] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (filteredLocations.length == 0) {
            setFilteredLocations(locations);
        }
    }, [locations]);

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const searchValue = searchParams.get("search");
        if (searchValue) {
            const decodedSearchValue = decodeURIComponent(searchValue);
            setSearch(decodedSearchValue);
            handleSearch(decodedSearchValue);
        } else {
            setFilteredLocations(locations);
        }
    }, [locations, location.search]);

    const handleSearch = useCallback(debounce((searchValue: string) => {
        const searchAttributes: (keyof LocationDetail)[] = ['city', 'street', 'number', 'name', 'subname', 'title', 'searchkeys', 'state'];

        if (searchValue.trim() === "") {
            setFilteredLocations(locations);
            return;
        }

        const searchedWords: string[] = searchValue.toLowerCase().split(" ").filter(word => word.trim() !== "");
        const positiveWords = searchedWords.filter(word => !word.startsWith("!"));
        const negativeWords = searchedWords.filter(word => word.startsWith("!")).map(word => word.substring(1));
        const filtered = locations.filter(location => {
            const combinedAttributes = searchAttributes.map(attribute => location[attribute]?.toString().toLowerCase() || "").join(" ");
            const includesAllPositiveWords = positiveWords.every(searchedWord => {
                return combinedAttributes.includes(searchedWord);
            });

            const excludesAllNegativeWords = negativeWords.every(negativeWord => {
                return !combinedAttributes.includes(negativeWord);
            });

            return includesAllPositiveWords && excludesAllNegativeWords && location.share !== 0;
        });
        setFilteredLocations(filtered);
    }, 300), [locations]);

    const handleSearchSubmit = (search: string) => {
        navigate(`?search=${search}`);
        handleSearch(search);
    };

    useEffect(() => {
        setMapKey(prevKey => prevKey + 1);
    }, [filteredLocations])

    const [mapSize, setMapSize] = useState({ width: '900px', height: '600px' });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1000) {
                setMapSize({ width: (window.innerWidth - 30) + "px", height: '400px' });
            } else {
                setMapSize({ width: '900px', height: '600px' });
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const { t } = useTranslation();

    const { totalUniqueAddresses, uniqueAddressCount, uniqueObjects, uniqueAddresses } = useMemo(() => {
        const addressMap: any = {};
        const addressToObjectMap: any = {};

        filteredLocations.forEach(location => {
            const key = `${location.city.trim().toLowerCase()}|${location.street ? location.street.trim().toLowerCase() : ''}|${location.number ? String(location.number).trim().toLowerCase() : ''}|${location.name ? location.name.trim().toLowerCase() : ''}|${location.group ? location.group.trim().toLowerCase() : ''}`;
            addressMap[key] = (addressMap[key] || 0) + 1;

            if (!addressToObjectMap[key]) {
                addressToObjectMap[key] = [];
            }
            addressToObjectMap[key].push(location);
        });

        const filteredLocationss: any[] = locations.filter(location =>
            location.city && location.city.trim() !== '' && // `city` jest wypełnione
            (!location.street || location.street.trim() === '') && // `street` jest puste, undefined lub null
            (location.number === undefined || location.number === null || String(location.number).trim() === '') // `number` jest puste, undefined lub null
        );

        const totalUnique = Object.keys(addressMap).length;
        const uniqueOnly = Object.values(addressMap).filter(count => count === 1).length;

        const uniqueObjs: any = [];
        Object.keys(addressMap).forEach(key => {
            if (addressMap[key] === 1 && addressToObjectMap[key].length === 1) {
                uniqueObjs.push(addressToObjectMap[key][0]);
            }
        });

        const uniqueAddrs = Object.keys(addressMap).filter(key => addressMap[key] === 1);

        return {
            totalUniqueAddresses: totalUnique,
            uniqueAddressCount: uniqueOnly,
            uniqueObjects: uniqueObjs,
            uniqueAddresses: uniqueAddrs
        };
    }, [filteredLocations]);

    return (
        <section className="md:w-[900px] mt-[39px] flex justify-center items-center mb-[200px]">
            <div>
                <h1
                    ref={headerRef}
                    tabIndex={-1}
                    className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-4xl font-bold font-['Roboto'] leading-10 text-center"}
                >
                    {t("Lokalizacje")} TOTUPOINT
                </h1>
                <div className="mt-[49px] md:h-[595px] flex justify-center" aria-label={t("Interaktywna mapa google - lokalizacja naszych znaczników")}>
                    <MemoizedMapGoogle key={mapKey} height={mapSize.height} width={mapSize.width} className="justify-center" locationsFiltered={filteredLocations} />
                </div>
                <div className="mt-[26px] md:ml-[20px] md:max-w-full max-w-[350px] mx-auto">
                    <h2 className={(isContrast ? "text-yellow-400" : " text-black ") + " mb-2 font-roboto"}>{t("Wyszukaj lokalizacje")}</h2>
                    <div className="md:w-[716px] mb-[20px]">
                        <label
                            htmlFor="default-search"
                            className="mb-2 text-custom-14px font-medium text-gray-900 sr-only dark:text-gray-300"
                        >
                            {t("Wyszukaj")}
                        </label>

                        <div
                            className="flex items-center p-4 py-3 w-full text-custom-14px text-gray-900 
               bg-gray-50 rounded-lg border border-gray-300 
               focus-within:ring-2 focus-within:ring-blue-500 
               focus-within:border-blue-500 relative"
                        >
                            <div className="pointer-events-none mr-2">
                                <svg
                                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M21 21l-6-6m2-5a7 7 0 
             11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </div>

                            <input
                                type="search"
                                id="default-search"
                                className="w-[80%] bg-transparent border-0 focus:outline-none"
                                placeholder={t("Wprowadź słowa kluczowe")}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearchSubmit(search);
                                    }
                                }}
                            />

                            <button
                                type="button"
                                onClick={() => handleSearchSubmit(search)}
                                aria-label={t("Wyszukaj lokalizacje")}
                                className="ml-2 text-white bg-[#12728C] hover:bg-blue-800 
                 focus:ring-4 focus:outline-none focus:ring-blue-300 
                 font-medium rounded-lg text-custom-14px px-4 py-2"
                            >
                                {t("Szukaj")}
                            </button>

                            <div className="relative ml-2">
                                <button
                                    onClick={() => setIsOpenTooltip(!isOpenTooltip)}
                                    className="text-gray-600 transition-colors duration-200 focus:outline-none 
                   dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"
                                    aria-label={t("Rozwiń więcej informacji o wyszukiwaniu")}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M9.879 7.519c1.171-1.025  3.071-1.025 4.242 0  
               1.172 1.025 1.172 2.687 0 3.712 
               -.203.179-.43.326-.67.442 
               -.745.361-1.45.999-1.45 1.827v.75 
               M21 12a9 9 0 11-18 0 9 9 0 0118 0 
               zm-9 5.25h.008v.008H12v-.008z"
                                        />
                                    </svg>
                                </button>

                                {isOpenTooltip && (
                                    <p
                                        className="absolute w-[300px] h-[135px] px-5 py-3 text-left text-gray-600 
                     -translate-x-1/2 bg-white rounded-lg shadow-md 
                     -top-[150px] left-1/2 dark:shadow-none 
                     shadow-gray-200 dark:bg-gray-800 dark:text-white"
                                    >
                                        {t("Wpisz jedno lub więcej oddzielonych spacjami słów...")}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="h-[600px] overflow-x-auto container-snap ">
                        <span className={(isContrast ? "text-yellow-400" : " text-black ") + " text-[14px]"}>
                            {t("Wyszukano")}: {filteredLocations.length} {t(getPunktForm(filteredLocations.length))}, {totalUniqueAddresses} {t(getObjectForm(totalUniqueAddresses))}
                        </span>
                        <FilteredLocation filteredLocations={filteredLocations} />
                    </div>
                </div>
            </div>
        </section >
    );
}