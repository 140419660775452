import React, { useContext, useMemo, useState, useEffect } from "react";
import { StatsContener } from "../../../components/stats/StatsContener";
import { LocationContext } from "../../../contexts/LocationContext";
import { AccessibilityContext } from "../../../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";
import { LocationDetail } from "../../../models/Location";
import { getObjectForm, getPunktForm } from "../../../helpers/correctForm";

export const StatisticsSection = () => {
    const { uniqueCity, locations } = useContext(LocationContext);
    const { isContrast } = useContext(AccessibilityContext);
    const { t } = useTranslation();

    const [showUniqueObjects, setShowUniqueObjects] = useState(false);

    // Obliczanie unikalnych adresów i unikalnych obiektów
    const { totalUniqueAddresses, uniqueAddressCount, uniqueObjects, uniqueAddresses } = useMemo(() => {
        const addressMap: any = {};
        const addressToObjectMap: any = {};

        locations.forEach(location => {
            const key = `${location.city.trim().toLowerCase()}|${location.street ? location.street.trim().toLowerCase() : ''}|${location.number ? String(location.number).trim().toLowerCase() : ''}|${location.name ? location.name.trim().toLowerCase() : ''}|${location.group ? location.group.trim().toLowerCase() : ''}`;
            addressMap[key] = (addressMap[key] || 0) + 1;

            if (!addressToObjectMap[key]) {
                addressToObjectMap[key] = [];
            }
            addressToObjectMap[key].push(location);
        });

        // **1. Wyświetlenie pełnej mapy adresów**

        // **2. Filtracja lokalizacji, gdzie tylko `city` jest wypełnione**
        const filteredLocations: any[] = locations.filter(location =>
            location.city && location.city.trim() !== '' && // `city` jest wypełnione
            (!location.street || location.street.trim() === '') && // `street` jest puste, undefined lub null
            (location.number === undefined || location.number === null || String(location.number).trim() === '') // `number` jest puste, undefined lub null
        );

        // **3. Wyświetlenie przefiltrowanych lokalizacji w console.table**
        const sortedFiltered = [...filteredLocations].sort((a, b) => a.city.localeCompare(b.city, 'pl', { sensitivity: 'base' }));

        const totalUnique = Object.keys(addressMap).length;
        const uniqueOnly = Object.values(addressMap).filter(count => count === 1).length;

        // Zbieranie unikalnych obiektów (adresów występujących tylko raz)
        const uniqueObjs: any = [];
        Object.keys(addressMap).forEach(key => {
            if (addressMap[key] === 1 && addressToObjectMap[key].length === 1) {
                uniqueObjs.push(addressToObjectMap[key][0]);
            }
        });

        // Przygotowanie listy unikalnych adresów
        const uniqueAddrs = Object.keys(addressMap).filter(key => addressMap[key] === 1);

        return {
            totalUniqueAddresses: totalUnique,
            uniqueAddressCount: uniqueOnly,
            uniqueObjects: uniqueObjs,
            uniqueAddresses: uniqueAddrs
        };
    }, [locations]);


    // Funkcja pomocnicza do kapitalizacji pierwszej litery
    const capitalize = (s: string): string => {
        if (typeof s !== 'string') return s;
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
        <section className="flex flex-col gap-20 justify-center mb-[70px] pt-[100px]" id="gdziejestesmy">
            <div className="md:mx-auto md:w-full ">
                <div className={(isContrast ? "text-yellow-400" : " text-black ") + " text-custom-40px font-bold leading-[47.41px] text-center md:mb-[100px] mb-[50px]"}>
                    <h2><span className="font-extrabold">TOTUPOINT</span> {t("w liczbach")}</h2>
                </div>
                <div className="flex flex-col gap-20">
                    <div className="flex md:flex-row flex-col justify-center md:gap-20 gap-10">
                        <div className="flex flex-col text-center">
                            <span className="text-cyan-700 text-custom-8xl font-semibold">
                                {locations.length}
                            </span>
                            <span className={(isContrast ? "text-yellow-400" : "text-black") + " text-custom-30px font-medium"}>
                                {capitalize(t(getPunktForm(locations.length)))}
                            </span>
                        </div>
                        <div className="flex flex-col text-center">
                            <span className="text-lime-600 text-custom-8xl font-semibold">
                                {totalUniqueAddresses}
                            </span>
                            <span className={(isContrast ? "text-yellow-400" : "text-black") + " text-custom-30px font-medium"}>
                                {capitalize(t(getObjectForm(totalUniqueAddresses)))}
                            </span>
                        </div>
                        <div className="flex flex-col text-center">
                            <span className="text-yellow-500 text-custom-8xl font-semibold">
                                {uniqueCity}
                            </span>
                            <span className={(isContrast ? "text-yellow-400" : "text-black") + " text-custom-30px font-medium"}>
                                {t("Miejscowości")}
                            </span>
                        </div>
                    </div>
                    <StatsContener />
                </div>
            </div>
        </section>
    );
};
