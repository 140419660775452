/**
 * Zwraca odpowiednią formę słowa "punkt" na podstawie liczby.
 * Forma pojedyncza ("punkt") pojawia się tylko wtedy, gdy n === 1.
 * @param n - liczba, dla której dobieramy formę
 * @returns "punkt", "punkty" lub "punktów"
 */
export function getPunktForm(n: number): string {
    // Jeśli liczba w zakresie 11-14, niezależnie od ostatniej cyfry, zwracamy "punktów"
    if (n % 100 >= 11 && n % 100 <= 14) {
        return "punktów";
    }
    // Forma pojedyncza tylko dla dokładnie 1
    if (n === 1) {
        return "punkt";
    }
    // Jeśli ostatnia cyfra to 2, 3 lub 4, zwracamy "punkty"
    const ones = n % 10;
    if (ones >= 2 && ones <= 4) {
        return "punkty";
    }
    return "punktów";
}

/**
 * Zwraca odpowiednią formę słowa "obiekt" na podstawie liczby.
 * Forma pojedyncza ("obiekt") pojawia się tylko wtedy, gdy n === 1.
 * @param n - liczba, dla której dobieramy formę
 * @returns "obiekt", "obiekty" lub "obiektów"
 */
export function getObjectForm(n: number): string {
    // Jeśli liczba w zakresie 11-14, niezależnie od ostatniej cyfry, zwracamy "obiektów"
    if (n % 100 >= 11 && n % 100 <= 14) {
        return "obiektów";
    }
    // Forma pojedyncza tylko dla dokładnie 1
    if (n === 1) {
        return "obiekt";
    }
    // Jeśli ostatnia cyfra to 2, 3 lub 4, zwracamy "obiekty"
    const ones = n % 10;
    if (ones >= 2 && ones <= 4) {
        return "obiekty";
    }
    return "obiektów";
}
