import { useContext, useEffect, useState } from "react";
import { AccessibilityContext } from "../contexts/AccessibilityContext";
import { useTranslation } from "react-i18next";

export const Footer: React.FC = () => {
    const [isContactInfoVisible, setContactInfoVisible] = useState(false);
    const { isContrast } = useContext(AccessibilityContext);
    const toggleContactInfo = () => {
        setContactInfoVisible(!isContactInfoVisible);
    };

    const { t } = useTranslation();

    return (
        <footer className="md:mt-auto bg-cyan-600 bg-opacity-10 footerContainer">
            <div className="md:w-[900px] w-[350px] ml-auto mr-auto flex flex-col py-[10px] mx-[10px] pt-[45px] h-[550px]">
                <div className="md:mx-0 mx-auto">
                    <img
                        className="w-[248px] h-[92px] object-cover"
                        alt="Pirs logo"
                        src="https://c.animaapp.com/KH8BuEma/img/pirs---logo--3--1@2x.png"
                    />
                </div>
                <div className="ml-2">
                    <button
                        onClick={toggleContactInfo}
                        type="button"
                        className={"bg-cyan-700 rounded-lg text-white hover:bg-cyan-950 text-[15px] mt-[20px]"}
                    >
                        <div className="flex items-center justify-center md:mx-[30px] mx-[15px] my-[6px]">
                            {t("Pokaż kontakty")}
                        </div>
                    </button>
                </div>
                <div className="flex flex-row gap-[57px] mt-[20px] md:ml-[85px] mx-[10px]">
                    <div>
                        <span className={`${isContrast ? "text-yellow-400" : "text-black"} text-custom-xl font-normal font-['Roboto'] flex flex-col gap-2`}>
                            <p>PIRS Creative Lab <br />Sp. z o.o.</p>
                            <p>ul.Twarda 44, <br />00-831 Warszawa</p>
                            <p> NIP 951-236-41-58</p>
                            <p> REGON 146510222</p>
                            <p> KRS 0000448627</p>
                        </span>
                    </div>
                    <div>
                        {isContactInfoVisible && (
                            <span className={`${isContrast ? "text-yellow-400" : "text-black"} text-custom-xl font-normal font-['Roboto'] flex flex-col gap-2`}>
                                <p>{t("Zarząd tel.") + ":"}
                                    <br />
                                    <a className="text-blue-900 font-roboto" href="tel:+48515407457">
                                        +48 515-407-457
                                    </a>
                                </p>
                                <p>{t("Wdrożenia tel.") + ":"}
                                    <br />
                                    <a className="text-blue-900 font-roboto" href="tel:+48698707737">
                                        +48 698-707-737
                                    </a>
                                    <br />
                                    <a className="text-blue-900 font-roboto" href="tel:+48731985969">
                                        +48 731-985-969
                                    </a>
                                </p>
                                <p>{t("Zamówienia tel.") + ":"}
                                    <br />
                                    <a className="text-blue-900 font-roboto" href="tel:+48516715673">
                                        +48 516-715-673
                                    </a>
                                </p>
                                <p>{t("e-mail") + ":"}
                                    <a className="text-blue-900 font-roboto" href="mailto:biuro@pirslab.pl">
                                        <br />biuro@pirslab.pl</a>
                                </p>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </footer >
    );
};